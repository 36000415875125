@import "../../../../Styles/Color.scss";

@keyframes glowing {
  0% {
    box-shadow: 0 0 3px #06add286;
  }

  50% {
    box-shadow: 0 0 25px #06add286;
  }

  100% {
    box-shadow: 0 0 3px #06add286;
  }
}

@keyframes glowtext {
  0% {
    font-size: 18px;
  }

  50% {
    font-size: 20px;
  }

  100% {
    font-size: 18px;
  }
}

@keyframes textglow {
  0% {
    font-size: 18px;
  }

  100% {
    font-size: 18px;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0px transparent;
  }

  50% {
    box-shadow: 0 0 0px transparent;
  }

  100% {
    box-shadow: 0 0 0px transparent;
  }
}

.slick-next {
  display: none !important;
}
.Edu_Dashboard_Header {
  background-color: #fff;
  width: 100%;
  padding: 15px 30px 15px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  top: 0px;
  height: 73px;

  .notification-wrapper {
    width: 22%;
    position: absolute;
    right: 10%;
    top: 9vh;
    padding: 15px 2px 2px 2px;
    background: #fff;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;

    .notification-section {
      .fa.fa-spinner.fa-spin {
        display: flex;
        justify-content: center;
        font-size: 20px;
      }

      .mark-view-all-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 5px 0px 10px 0px;

        .mark-all-as-read {
          text-align: left;
          font-family: Rubik-Medium !important;
          margin: 0 10px;
          font-size: 16px;
          color: #51bbce;
          text-decoration: underline;
          cursor: pointer;
        }

        .view-all {
          text-align: right;
          font-family: Rubik-Medium !important;
          margin: 0 10px;
          font-size: 16px;
          color: #51bbce;
          cursor: pointer;
        }
      }

      .notification {
        cursor: pointer;
        align-items: center;
        border-bottom: 1px solid $Primary_Font_Color;
        padding: 15px 0;

        .notification-txt {
          display: flex;
          padding: 0 15px;

          .notificatim-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          p {
            font-family: Rubik-Medium !important;
            margin: 0 10px;
            font-size: 14px;
            color: $Primary_Font_Color;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }

        .notification-date {
          font-family: Rubik-Medium !important;
          margin: 10px 10px 0px 10px;
          font-size: 13px;
          color: $Primary_Font_Color;
          text-align: right;
        }
      }
    }
  }

  .Navbar_Right_Side_Container {
    display: flex;

    // .btn{
    //   position:relative;
    //   overflow:hidden;
    //   text-transform:uppercase;
    //   letter-spacing:5px;
    //   color: #888;
    //   font-size: 20px;
    //   font-family: "Oswald", sans-serif;
    //   font-weight:lighter;
    //   border: 0;
    //   cursor: pointer;
    //   padding: 10px 20px;
    //   border: 1px solid rgba(143,144,145,0.2);
    //   background-color:$primaryblue;
    //   transition: all 0.5s ease-in-out;
    //   display: none;
    // }
    // .btn:focus {
    //   color: #fff;
    //   outline:none;
    // }
    // .btn:before {
    //   position: absolute;
    //   top: 0;
    //   left: -75%;
    //   z-index: 2;
    //   display: block;
    //   content: '';
    //   width: 50%;
    //   height: 100%;
    //   background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    //   background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    //   -webkit-transform: skewX(-25deg);
    //   transform: skewX(-25deg);
    //   // -webkit-animation: shine 2s;
    //   // animation: shine 2s infinite;
    // }
    // .btn:hover{
    //   color:#fff;
    // }
    // .btn:hover::before {

    // }
    // @-webkit-keyframes shine {
    //   100% {
    //     left: 125%;
    //     background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    //     background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    //     -webkit-transform: skewX(-25deg);
    //     transform: skewX(-25deg);
    //   }
    // }

    .free_class_btn {
      position: relative;
      overflow: hidden;
      margin: 0 5px;

      button {
        width: 190px;

        height: 58px;
        border-radius: 14px;
        // border: 3px solid $primaryblue;
        background-color: $primaryblue;
        // background-color: transparent;
        color: $primary_White;
        border: 3px solid $primary_White;
        // color: $primaryblue;
        box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
        font-family: Rubik-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.4px;
        cursor: pointer;
        transition: 1.5s !important;
        // animation: explore infinite 2s alternate;
        border: none;
        height: 40px;

        &:before {
          position: absolute;
          top: 0;
          left: -75%;
          z-index: 2;
          display: block;
          content: "";
          width: 55%;
          height: 100%;
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
          -webkit-animation: shine 2s;
          animation: shine 2s infinite;
        }
      }

      @keyframes shine {
        100% {
          left: 125%;
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
        }
      }
    }

    .signin_with_meetube_btn {
      position: relative;
      overflow: hidden;
      margin: 0 5px;

      button {
        width: 190px;

        height: 58px;
        border-radius: 14px;
        // border: 3px solid $primaryblue;
        background-color: $primary_White;
        // background-color: transparent;
        color: $primaryblue;
        border: 3px solid $primaryblue !important;
        // color: $primaryblue;
        box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
        font-family: Rubik-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.4px;
        cursor: pointer;
        transition: 1.5s !important;
        // animation: explore infinite 2s alternate;
        border: none;
        height: 40px;

        &:before {
          position: absolute;
          top: 0;
          left: -75%;
          z-index: 2;
          display: block;
          content: "";
          width: 55%;
          height: 100%;
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
          -webkit-animation: shine 2s;
          animation: shine 2s infinite;
        }

        &:hover {
          transition: 0.1s;
          background-color: $primaryblue;
          color: $primary_White;
        }
      }

      @keyframes shine {
        100% {
          left: 125%;
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
        }
      }
    }

    .Navbar_Button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #e8e9ef;
      border-radius: 50px;
      margin: 0px 10px;
      width: 149px;
      // transition: 1s !important;
      -webkit-animation: glowing 1500ms infinite;
      height: 40px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        -webkit-animation: glow 1500ms infinite;

        .Navbar_Button_Text {
          span {
            -webkit-animation: textglow 1500ms infinite;
          }
        }
      }

      .Navbar_Button_Icon {
        background-color: $primaryblue;
        // width: 70px;
        // height: 70px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        z-index: 999;

        .Icon {
          width: 30px;
        }
      }

      .Navbar_Button_Text {
        font-size: 20px;
        // padding: 10px 15px;
        padding-left: 10px;
        color: #9b9b9b;
        align-items: center;
        display: flex;
        width: 99px;

        span {
          -webkit-animation: glowtext 1500ms infinite;
        }

        .timer-box-design {
          margin: 0px 5px;
          padding: 7px;
          background-color: #fff;
          border-radius: 5px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    .disabled.blick_disable {
      .Navbar_Button_Text {
        span {
          -webkit-animation: none;
        }
      }
    }

    .Navbar_Single_Icon {
      width: 40px;
      height: 40px;
      margin: 0px 10px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #e8e9ef;
      position: relative;

      .notification-count {
        position: absolute;
        right: -5px;
        top: -5px;
        padding: 4px 5px;
        background: #eeb75a;
        border-radius: 50%;
        height: 25px;
        // width: 25px;
        min-width: 25px;
        text-align: center;
        color: white;
        font-size: 14px;
      }

      &:hover {
        box-shadow: 4px 2px 15px -6px rgba(0, 0, 0, 0.6);
      }

      .Icon {
        width: 26px;
        height: 25px;
        object-fit: contain;
      }
    }

    .Navbar_Single_Icon_DropDown {
      display: flex;
      position: relative;
      background-color: $Active_Button_Color;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;
      margin: 0px 10px;
      padding: 4px 4px;
      height: 40px;

      .Profile {
        width: 35px;
        height: 35px;
        border-radius: 50px;
        border: 3px solid $primary_White;
      }

      .DropDownIconContainer {
        padding: 10px 15px 10px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 60px 60px 0px;

        .DropDownIcon {
          width: 15px;
        }
      }

      .dropdown_Main_Container {
        position: absolute;
        top: 120%;
        left: 0;
        z-index: 2;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
        background-color: $Active_Button_Color;
        border-radius: 10px;
        width: 165px;

        div {
          padding: 10px 11px;
          color: #ffffff;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          &:hover {
            background-color: rgba(160, 160, 160, 0.14);
            cursor: pointer;
          }
        }

        .refer_class_block img {
          width: auto;
          height: 20px;
        }
      }
    }
  }
}

.Responsive_Header_Container {
  background-color: #aee8f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5%;
  height: 66px;
  position: sticky;
  top: 0;

  .Link_responsive {
    color: rgb(255, 255, 255);
    margin: 5px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }

  .Border {
    border-top: 3px solid #fff;
    padding-top: 20px;
  }

  // .Profile_DropDown {
  //   display: flex;
  //   position: relative;
  //   background-color: #fff5e3;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 50px;
  //   cursor: pointer;
  //   width: fit-content;
  //   margin: 0px 10px;
  //   margin-left: 25px;
  //   margin-top: 10px;
  //   .Profile {
  //     width: 47px;
  //     height: 47px;
  //     border-radius: 50px;
  //     border: 3px solid #f2cb72;
  //   }
  //   .DropDownIconContainer {
  //     padding: 10px 15px 10px 25px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border-radius: 0px 60px 60px 0px;
  //     .DropDownIcon {
  //       width: 15px;
  //     }
  //   }
  // }
}

.Responsive_Text {
  color: #fff;
  font-size: 20px;
}

.navbar {
  background-color: #aee8f4;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-menu-educator {
  background-color: #aee8f4;
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999999;
}

.nav-menu-educator.active {
  left: 0;
  transition: 350ms;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-text-educator {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0px 5px 25px;
  list-style: none;
  flex-direction: column;
  justify-content: flex-start;
}

.yellow .Title_Sidebar {
  color: #f2cb72;
  display: none;
}

.nav-text-educator button {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  // padding: 0 16px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  background-color: transparent;
}

.nav-menu-items-edu {
  width: 100%;
  height: 70vh;
  overflow: auto;

  .nav-text-educator {
    display: block;

    .Navbar_Button {
      display: flex;
      max-width: 160px;
      align-items: center;
      cursor: pointer;
      background-color: #fff5e3;
      border-radius: 50px;
      margin: 0px 10px 0px 0px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .Navbar_Button_Icon {
        background-color: #f2cb72;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        z-index: 999;

        .Icon {
          width: 25px;
        }
      }

      .Navbar_Button_Text {
        font-size: 20px;
        padding: 10px 15px;
        color: #f2cb72;
        align-items: center;
        display: flex;
      }
    }
  }
}

.Sidebar_Data {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.navbar-toggle {
  background-color: #aee8f4;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 30px;
  padding-right: 25px;
  color: #fff;
}

.Title_Sidebar {
  margin-left: 16px;
}

.Slider_Responsive {
  background-color: #f1f7f8;

  // padding: 0px 8px;
  .SubSliderContainer {
    .Sub_Link {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      text-decoration: none;
      border-radius: 15px;
      margin: 20px 5px;
      color: #fff;
      height: 50px;
      text-align: flex-end;
      font-size: 12px;
      border: 3px solid #fff;
      box-shadow: 0px 2px 14px -3px #88888859;
      width: 140px;
    }

    .Slider_Icon_Header {
      font-size: 15px;
      margin-right: 15px;

      &:nth-child(2) {
        display: none !important;
      }
    }
  }
}

.Logo_Responsive {
  width: 120px;
}

@media screen and (max-width: 767px) {
  .navbar-toggle {
    justify-content: flex-end;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      padding: 20px 3% !important;

      .educator_menu {
        width: auto !important;
      }

      .meeko_logo {
        width: auto !important;
      }

      .educator_profile {
        width: auto !important;

        .notification_icon {
          position: relative;

          .notification-count {
            position: absolute;
            right: -10px;
            padding: 3px 6px;
            top: -10px;
            background: red;
            border-radius: 50%;
            color: white;
            font-size: 12px;
          }
        }

        .signin_with_meetube_btn {
          display: none;
        }

        .free_class_btn {
          display: none;
        }

        .Navbar_Single_Icon_DropDown {
          .DropDownIconContainer {
            padding: 10px 10px 10px 10px !important;
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .Slider_Responsive {
    .live-class-option {
      .option {
        margin: 20px 2px;
      }
    }

    .SubSliderContainer {
      .Sub_Link {
        span {
          width: 50%;
        }
      }

      .Sub_Link {
        margin: 20px 2px;
      }
    }
  }

  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_profile {
        width: 20% !important;

        .Navbar_Single_Icon_DropDown {
          .dropdown_Main_Container_user {
            width: 170px;
          }
        }
      }

      .meeko_logo {
        // width: 40%;
      }
    }
  }
}

@media all and (min-width: 981px) and (max-width: 1025px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_profile {
        .Navbar_Single_Icon_DropDown {
          .dropdown_Main_Container_user {
            width: 170px;
          }
        }
      }
    }
  }

  .Slider_Responsive {
    .SubSliderContainer {
      .Sub_Link {
        width: 160px !important;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_profile {
        .signin_with_meetube_btn {
          button {
            width: auto !important;
            height: auto !important;
            font-size: 14px !important;
            padding: 8px 0px 0px 0px !important;
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }
        }

        .free_class_btn {
          button {
            width: auto !important;
            height: auto !important;
            font-size: 14px !important;
            padding: 8px 0px 0px 0px !important;
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }
        }

        .Navbar_Single_Icon_DropDown {
          .DropDownIconContainer {
            padding: 10px 18px 10px 10px !important;
          }
        }
      }
    }
  }

  .Navbar_Button {
    display: none !important;
  }

  .nav-menu-items-edu {
    .yellow {
      display: none !important;
    }
  }

  .Navbar_Single_Icon {
    &:nth-child(3) {
      display: none !important;
    }

    &:nth-child(4) {
      display: none !important;
    }

    &:nth-child(6) {
      display: none !important;
    }
  }
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_profile {
        .signin_with_meetube_btn {
          button {
            width: 155px !important;
            height: 48px !important;
            font-size: 14px !important;
          }
        }

        .free_class_btn {
          button {
            width: 155px !important;
            height: 48px !important;
            font-size: 14px !important;
          }
        }

        .Navbar_Single_Icon_DropDown {
          .DropDownIconContainer {
            padding: 10px 18px 10px 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_menu {
        width: 20%;
      }

      .meeko_logo {
        width: 50%;
        display: flex;
        justify-content: center;
      }

      .nav-menu-educator {
        .nav-menu-items-edu {
          height: 90vh;

          .signin_with_meetube_btn {
            margin: 15px 0;

            button {
              width: 190px;

              height: 50px;
              border-radius: 14px;
              border: 3px solid $primary_White;
              background-color: $primaryblue;
              color: $primary_White;
              box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
              font-family: Rubik-Medium;
              font-size: 17px;
              font-weight: 500;
              letter-spacing: 0.4px;
              margin-left: 23px;
              cursor: pointer;
            }
          }

          .free_class_btn {
            margin: 15px 0;

            button {
              width: 190px;

              height: 50px;
              border-radius: 14px;
              border: 3px solid $primary_White;
              background-color: $primaryblue;
              color: $primary_White;
              box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
              font-family: Rubik-Medium;
              font-size: 17px;
              font-weight: 500;
              letter-spacing: 0.4px;
              margin-left: 23px;
              cursor: pointer;
            }
          }

          .nav-text-educator {
            .Navbar_Button {
              margin: 3px 10px 5px 0px;
            }

            .Navbar_Button {
              .Navbar_Button_Text {
                .timer-box-design {
                  margin: 0px 5px;
                  padding: 7px;
                  background-color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .educator_profile {
        display: flex;
        width: 30%;
        justify-content: flex-end;

        .signin_with_meetube_btn {
          button {
            width: 190px;

            height: 58px;
            border-radius: 14px;
            border: 3px solid $primary_White;
            background-color: $primaryblue;
            color: $primary_White;
            box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
            font-family: Rubik-Medium;
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0.4px;
            cursor: pointer;
          }
        }

        .free_class_btn {
          button {
            width: 190px;

            height: 58px;
            border-radius: 14px;
            border: 3px solid $primary_White;
            background-color: $primaryblue;
            color: $primary_White;
            box-shadow: 0px 0px 14px -7px rgb(0 0 0 / 80%);
            font-family: Rubik-Medium;
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0.4px;
            cursor: pointer;
          }
        }

        .Navbar_Single_Icon_DropDown {
          display: flex;
          position: relative;
          background-color: $Active_Button_Color;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          cursor: pointer;
          margin: 0px 10px;

          .Profile_user {
            width: 45px;
            height: 45px;
            border-radius: 50px;
            border: 3px solid #f2cb72;
          }

          .DropDownIconContainer {
            padding: 10px 10px 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 60px 60px 0px;

            img {
              height: 10px !important;
              width: 15px !important;
            }
          }

          .dropdown_Main_Container_user {
            position: absolute;
            top: 120%;
            left: 0;
            z-index: 2;
            box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
            background-color: #efb959;
            border-radius: 10px;

            div {
              padding: 10px 15px;
              color: #ffffff;
              display: flex;
              align-items: center;
              margin-right: 10px;
            }

            img {
              height: 20px;
              width: 20px;
              border-radius: 0;
              margin-right: 10px;
              object-fit: contain;
            }
          }
        }

        .notification_icon {
          align-items: center;
          display: flex;
          margin: 0 10px;
          position: relative;

          .notification-count {
            position: absolute;
            right: -15px;
            top: -10px;
            padding: 4px 5px;
            background: red;
            border-radius: 50%;
            height: 24px;
            min-width: 22px;
            text-align: center;
            color: white;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Dashboard_Main_Container {
    .Responsive_Header_Container {
      .educator_profile {
        .Navbar_Single_Icon_DropDown {
          margin: 0px 5px;

          .Profile_user {
            width: 35px;
            height: 35px;
            border-radius: 50px;
            border: 2px solid #f2cb72;
          }

          .DropDownIconContainer {
            padding: 10px 10px 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 50px 50px 0px;

            img {
              height: 15px;
              width: 15px;
            }
          }

          .dropdown_Main_Container_user {
            position: absolute;
            top: 120%;
            left: -15px;
            z-index: 2;
            box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
            background-color: #efb959;
            border-radius: 10px;

            div {
              padding: 10px 10px;
              color: #ffffff;
              display: flex;
              align-items: center;
              margin-right: 0px;
              font-size: 15px;
              width: 145px;
            }

            img {
              height: 15px;
              width: 15px;
              border-radius: 0;
              margin-right: 10px;
              object-fit: contain;
            }
          }
        }

        .notification_icon {
          align-items: center;
          display: flex;
          margin: 0 10px;
        }
      }
    }
  }
}

// img.Logo {
//     height: 100%;
//     width: 180px;
// }
