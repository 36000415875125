@import "../../../Styles/Color.scss";

.freeclass_popup {
  .popup {
    .popup_inner {
      .popup_Title {
        color: $primaryblue;
        font-family: Rubik-Medium;
        font-size: 28px !important;
        line-height: 40px;
        letter-spacing: 0.5px;
        width: 75%;
        margin: auto;
        padding-bottom: 40px !important;
      }
      .add_btn {
        display: flex;
        button {
          cursor: pointer;
          width: 285px;
          height: 63px;
          border: none;
          border-radius: 14px;
          font-family: Rubik-Medium;
          background-color: $primaryblue;
          color: $primary_White;
          font-weight: 600;
          font-size: 20px;
          margin: auto;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
@media all and (min-width: 320px) and (max-width: 480px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        padding: 40px 17px 30px 17px;
        height: fit-content !important;
        .add_btn {
          button {
            width: 187px;
            height: 44px;
            font-size: 16px;
            font-weight: normal;
          }
        }

        .popup_Title {
          width: 89%;
          font-size: 15px !important;
          line-height: 20px;
          padding-bottom: 27px !important;
          font-weight: normal;
        }
      }
    }
    .Popup_Close_Btn {
      right: 10px !important;
      top: 12px !important;
    }
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        padding: 30px 17px;
        height: fit-content !important;
        .add_btn {
          button {
            width: 187px;
            height: 44px;
            font-size: 16px;
            font-weight: normal;
          }
        }

        .popup_Title {
          width: 89%;
          font-size: 18px !important;
          line-height: 32px;
          padding-bottom: 27px !important;
          font-weight: normal;
        }
      }
    }
    .Popup_Close_Btn {
      right: 10px !important;
      top: 12px !important;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        padding: 30px 25px;
        .add_btn {
          button {
            width: 204px;
            height: 47px;
            font-size: 18px;
          }
        }

        .popup_Title {
          width: 89%;
          font-size: 21px !important;
          line-height: 32px;
        }
      }
    }
    .Popup_Close_Btn {
      right: 15px;
      top: 15px;
    }
  }
}

@media all and (min-width: 981px) and (max-width: 1024px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        .add_btn {
          button {
            width: 210px;
            height: 50px;
            font-size: 18px;
          }
        }

        .popup_Title {
          width: 89%;
          font-size: 23px !important;
        }
      }
    }
    .Popup_Close_Btn {
      right: 18px;
      top: 15px;
    }
  }
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        .add_btn {
          button {
            width: 220px;
            height: 52px;
            font-size: 18px;
          }
        }

        .popup_Title {
          width: 84%;
          font-size: 23px !important;
        }
      }
    }
    .Popup_Close_Btn {
      right: 25px;
      top: 15px;
    }
  }
}

@media all and (min-width: 1026px) and (max-width: 1140px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        .add_btn {
          button {
            width: 255px;
            height: 56px;
          }
        }

        .popup_Title {
          width: 84%;
          font-size: 26px !important;
        }
      }
    }
  }
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        .add_btn {
          button {
            width: 255px;
            height: 56px;
          }
        }

        .popup_Title {
          width: 84%;
          font-size: 26px !important;
        }
      }
    }
    .Popup_Close_Btn {
      right: 19px;
      top: 16px;
    }
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  .freeclass_popup {
    .popup {
      .popup_inner {
        .add_btn {
          button {
            width: 269px;
            height: 60px;
          }
        }

        .popup_Title {
          width: 84%;
        }
      }
    }
  }
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
  .freeclass_popup {
    .popup .popup_inner {
    }
  }
}
